import React from 'react';

import { NosotrosCont } from '../styles/components/nosotros.styled'
import { ReactComponent as LogoOblek } from '../assets/logos/oblek-logo.svg'

import Seo from "../components/seo";
import Layout from "../components/layout";


const Nosotros = () => {
  return(
    <Layout>
      <Seo title="Nosotros"/>
      <NosotrosCont>
        <div className='header'>
          <div className='logo'>
            <LogoOblek />
          </div>
          <div className='text'>
            <p>
            Somos una empresa mexicana que desde <b>1997</b> nos hemos posicionado como una de las <b>empresas líderes</b> en el mercado del monitoreo y consultoría de medios e información publicada.
            </p>
          </div>
          <div className='bg'/>
        </div>
        <div className='parrafo'>
          <p>A lo largo de los años hemos evolucionado como expertos en <b>big data</b>, desarrollo de productos y contenidos digitales.</p>
        </div>
        <div className='myoblek'>
          <div className='title'>
            <h3><b>MyOblek</b> Nace en el año 2020</h3>
          </div>
          <div className='text'>
            <p>Es una propuesta para resolver las necesidades de monitoreo en el mercado mexicano. </p>
            <p>Pretendemos convertirnos en tu aliado principal para que puedas:</p>
          </div>
        </div>
        <div className='myOblekList'>
          <div className='cont'>
            <div className='bg'/>
            <ul>
              <li>Dar seguimiento, analizar y posicionarte contra tus competidores para complementar tus estrategias de comunicación y toma de decisiones.</li>
              <li>Capturar contenido proveniente de todos los medios de comunicación.</li>
              <li>Comprender el impacto que generó tu estrategia de comunicación.</li>
              <li>Navegar a través de todas tus menciones, las de tu marca y/o negocio y/o las de tu competencia.</li>
              <li>Generar búsquedas a partir de actores, temas, marcas o empresas de tu interés.</li>
            </ul>
          </div>
        </div>
        <div className='howOblek'>
          <div className='title'>
            <h3>¿Cómo puedo contactar con el equipo MyOblek?</h3>
          </div>
          <div className='text'>
            <p>Dentro de la plataforma tenemos un chat de asistencia y servicio al cliente. Si no resolvimos tu pregunta envíanos un correo a <b>hola@myoblek.com</b></p>
          </div>
        </div>
      </NosotrosCont>
    </Layout>
  )
};

export default Nosotros;