import styled from "styled-components";
import { colors } from "../variables";

//IMAGES
import imageServers from "../../assets/images/servers.jpg";
import figuresPNG from "../../assets/images/fugures.png";

export const NosotrosCont = styled.div`
  /* ===========================  */
  /* ====== styles Mobile ======  */
  /* ===========================  */

  .header {
    height: calc(100vh - 55px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    .bg {
      position: absolute;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${imageServers}) no-repeat top center;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.3;
      bottom: 0;
    }
    .logo {
      margin-bottom: 80px;
      svg {
        height: 40px;
        width: 200px;
      }
    }
    .text {
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        b {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: ${colors.purple};
        }
      }
    }
  }
  .parrafo {
    padding: 0 5%;
    margin: 100px 0;
    p {
      font-family: Montserrat Bold;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 151.4%;
      text-align: center;
      b {
        font-family: Montserrat Bold;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 151.4%;
        text-align: center;
        color: ${colors.purple};
      }
    }
  }
  .myoblek {
    padding: 0% 5%;
    .title {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      h3 {
        max-width: 180px;
        font-family: Montserrat Bold;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 37px;
        text-align: center;
        b {
          font-family: Montserrat Bold;
          font-style: normal;
          font-weight: Bold;
          font-size: 30px;
          line-height: 37px;
          text-align: center;
          color: ${colors.purple};
        }
      }
    }
    .text {
      margin-bottom: 50px;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 151.4%;
        text-align: center;
      }
    }
  }
  .myOblekList {
    .cont {
      padding: 50px 5%;
      background: ${colors.torquioseBlue};
      background-size: cover;
      border-radius: 24px;
      position: relative;
      .bg {
        background: url(${figuresPNG}) no-repeat center center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.45;
        z-index: 0;
      }
      ul {
        display: flex;
        flex-direction: column;
        list-style-position: inside;
        position: relative;
        z-index: 1;
        li {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 50px;
          &::marker {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
  .howOblek {
    padding: 0 5%;
    .title {
      margin-top: 130px;
      margin-bottom: 40px;
      h3 {
        font-family: Montserrat Bold;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 37px;
        text-align: center;
      }
    }
    .text {
      margin-bottom: 130px;
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        b {
          font-family: Montserrat Bold;
          font-style: normal;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
        }
      }
    }
  }

  /* ===========================  */
  /* ====== styles Tablet ======  */
  /* ===========================  */

  @media (min-width: 768px) {
    .header {
      height: calc(100vh - 55px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 5%;
      max-height: 800px;
      .logo {
        margin-bottom: 80px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 300px;
          height: 60px;
        }
      }
      .text {
        width: 80%;
        p {
          font-size: 28px;
          line-height: 151.4%;
          text-align: center;
          b {
            font-size: 28px;
            line-height: 151.4%;
          }
        }
      }
    }
    .parrafo {
      display: flex;
      justify-content: center;
      margin-top: 0;
      p {
        font-size: 28px;
        line-height: 151.4%;
        text-align: center;
        max-width: 729px;
        b {
          font-size: 28px;
          line-height: 151.4%;
          color: ${colors.purple};
        }
      }
    }
    .myoblek {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      .title {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        width: 240px;
        h3 {
          max-width: 240px;
          font-size: 36px;
          line-height: 44px;
          text-align: left;
          b {
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            color: ${colors.purple};
          }
        }
      }
      .text {
        margin-bottom: 50px;
        width: calc(100% - 240px);
        p {
          font-size: 28px;
          line-height: 151.4%;
          text-align: left;
        }
      }
    }
    .myOblekList {
      padding: 0% 5%;
      .cont {
        padding: 0 5% 0px 5%;
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          list-style-position: outside;
          li {
            font-size: 24px;
            line-height: 29px;
            text-align: left;
            margin-left: 20px;
            margin-right: 60px;
            margin-bottom: 20px;
            position: relative;
            max-height: 120px;
            &:nth-child(1) {
              grid-area: 1 / 1 / 2 / 3;
              text-align: center;
              display: flex;
              align-items: center;
              padding-top: 40px;
              max-width: 600px;
              margin-bottom: 0;
              margin-left: auto;
              margin-right: auto;
              padding-bottom: 40px;
              max-height: 200px;
              &:after {
                content: "";
                position: absolute;
                background: black;
                width: 8.5px;
                height: 8.5px;
                border-radius: 50%;
                left: -22px;
                top: 49px;
                margin: auto;
              }
            }
          }
        }
      }
    }
    .howOblek {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        max-width: 670px;
        h3 {
          font-size: 36px;
          line-height: 44px;
          text-align: center;
        }
      }
      .text {
        max-width: 576px;
        p {
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          b {
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
    }
  }

  /* ===========================  */
  /* ===== styles Desktop ======  */
  /* ===========================  */

  @media (min-width: 1024px) {
    .header {
      height: calc(100vh - 55px);
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      padding: 0 10%;
      max-height: 700px;
      .logo {
        margin: 0;
      }
      .text {
        p {
          text-align: left;
        }
      }
    }
    .parrafo {
      padding: 0 10%;
    }
    .myoblek {
      padding: 0% 10%;
    }
    .myOblekList {
      padding: 0% 10%;
    }
    .howOblek {
      padding: 0% 10%;
    }
  }
`;
